import request from "@/utils/request";

// 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/clue/getClueList",
    method: "get",
    params: query
  });
}

// 查询跟进列表
export function fetchFollowList(query) {
  return request({
    url: "/crm/clue/getFollowList",
    method: "get",
    params: query
  });
}

// 获取数量
export function fetchCount(query) {
  return request({
    url: "/crm/clue/getNumber",
    method: "get"
  });
}

// 添加线索
export function addClue(data) {
  return request({
    url: "/crm/clue/addClue",
    method: "post",
    data
  });
}
// 编辑线索
export function editClue(data) {
  return request({
    url: "/crm/clue/updateClue",
    method: "PUT",
    data
  });
}

// 根据销售线索Id获取信息
export function getDetailByClueId(params) {
  return request({
    url: "/crm/clue/getId",
    method: "get",
    params
  });
}

// 删除线索
export function delList(clueIds) {
  return request({
    url: "/crm/clue/deleteClue/" + clueIds,
    method: "delete"
  });
}

// 转为客户
export function changeClient(data) {
  return request({
    url: "/crm/clue/transferToCustomer/" + data,
    method: "PUT"
  });
}

// 线索改为已转化
export function changeConversion(clueId) {
  return request({
    url: "/crm/clue/changeConversion/" + clueId,
    method: "PUT"
  });
}

// 判断线索是否已转化
export function judgeIsConversion(clueIds) {
  return request({
    url: "/crm/clue/judgeIsConversion/" + clueIds,
    method: "get"
  });
}

// 根据用户id查询用户信息
export function fetchUserInfo(params) {
  return request({
    url: "/system/user/baseMsg/" + params,
    method: "get"
  });
}

// 新建跟进
export function addFollowClue(data) {
  return request({
    url: "/crm/clue/addFollow",
    method: "POST",
    data
  });
}

// 编辑跟进
export function editFollowClue(data) {
  return request({
    url: "/crm/clue/updateFollow",
    method: "PUT",
    data
  });
}

// 删除跟进
export function delFollowList(data) {
  return request({
    url: "/crm/clue/deleteFollow",
    method: "delete",
    data
  });
}

// 转移客户
export function fetchTransferClue(leaderId, clueIds) {
  return request({
    url: `/crm/clue/transferClue/${leaderId}/${clueIds}`,
    method: "put"
  });
}
